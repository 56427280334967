
.caller-popup-content {
    width: 100% !important;
    max-width: 100% !important;
}


.close-caller-report-box{
    position:absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
   margin-bottom: 10px !important;
}
.caller-popup-content .caller-popup-container {
    width: 100% !important;
    max-width: 100% !important;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 12px;
}

.countinue-button {
    width: 120px;
    margin: auto;
    margin-top: 20px;
}

.countinue-button button {
    width: 100%;
    height: 40px;
    background-color: #1976d2;
    color: #fff;
    border: none;
    border-radius: 8px;
}

.report-card{
    margin: 10px !important;
}
.report-row{
    display: flex;
   
}

.report-value{
    margin-left: 20px;
}

.caller-report-section-container{
    width: 95%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
}



.count-and-amount-details{
    width: 95%;
    margin: auto;
    display: flex;
    justify-content: center;
    padding: 20px 0px;
}

@media screen and (max-width:768px) {
    .count-and-amount-details{
        width: 90%;
        flex-direction: column;
        padding: 5px 0px;
    }
    
}