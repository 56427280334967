.wallet-history-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0;
    gap: 10px;
}

.wallet-history-count-container {
    display: flex;
    width: 70%;
    flex-direction: column;
}

.wallet-history-deposit-withdrawal-container {
    display: flex;
    width: 26%;
    background-color: #FF9A43;
    padding: 10px;
    flex-direction: column;
    height: 50%;
}

.wallet-history-count-box {
    display: flex;
    background-color: #FF9A43;
    justify-content: column;
    padding: 10px;
    width: 100%;
    justify-content: space-between;
}

.wallet-history-count-box-game {
    width: 30%;
}


.wallet-history-count-box-win-loss {
    width: 30%;
}


.wallet-history-count-box-total {
    width: 30%;
}

.wallet-history-text {
    color: #000;
    margin-bottom: 0;
    padding: 4px 0;
    font-size: 15px;
}

.wallet-history-box-header {
    border-bottom: 1px solid #000;
    margin-bottom: 10px;
}

.wallet-history-box-footer {
    border-top: 1px solid #000;
    margin-top: 10px;
}

.wallet-color {
    color: blue;
    font-weight: 500;
}

.wallet-span {
    font-weight: bold;
}

.table-wallet td {
    white-space: normal;
}

.table-wallet tr td {
    font-size: 13px;
    font-weight: 600;
    color: black;
}

.box-shd {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.wallet-history-container {
    display: flex;
    justify-content: space-between;
}

.wallet-history-count-container {
    flex: 1;
}

.wallet-history-deposit-withdrawal-container {
    flex: 1 1 1;
}


.main-div {
    margin-bottom: 1rem;
}

.container-fluid-starline {
    padding-left: 5px;
}

.table-filters-select-starline {
    height: 28px;
    /* padding-bottom: 3px;
    margin: 3px 0px; */
}

.icon-button {
    background: #fff !important;
    border-radius: 0px !important;
    height: 25.5px !important;
    margin-bottom: 6px !important;
    margin-top: 3px !important;
}
.icon-button-wallet {
    background: #fff !important;
    border-radius: 0px !important;
    height: 26px !important;
    margin-bottom: 4px !important;
    margin-top: 1px !important;
}

.iconbtn-div {
    display: flex;
    align-items: center;
    height: 26px;
}

.market-name {
    width: 200px;
    height: 70px;
    white-space: normal;
}

@media only screen and (max-width: 600px) {
    .main-div {
        margin-top: 1.5rem;
    }

    .submit-wallet {
        margin-top: 15px;
    }

    .wallet-history-container {
        flex-direction: column;
    }

    .wallet-history-count-container,
    .wallet-history-deposit-withdrawal-container {
        width: 100%;
    }
}