#dropdownMenuButton {
  /* color: #1850ef !important; */
}

.sign {
  /* color: #9942dd !important; */
}

.navbar .nav-link .sign {
  color: #6a56ee;
}

.table.table-view {
  border-collapse: collapse;
  border-spacing: 0;
  /* margin-top: 5px; */
}

.table.table-view > thead tr {
  background: linear-gradient(to right, #1f4dd1 0, #cf32dd 100%);
}

table tbody tr td {
  border: 1px solid black !important;
}

.bg-table {
  background: none !important;
}
.table-head {
  padding: 4px 0px 2px 10px;
  margin: 0;
}

.table-view tr td {
  border-collapse: collapse;
  border-right: 0;
  padding: 5px;
  font-size: 14px;
  /* border: none; */
  vertical-align: middle;
  color: black;
}

.table-view tr th {
  padding: 5px;
  color: #fff;
  font-size: 14px;
  border: 1px solid #000;
}

.table-view tr:nth-child(odd) {
  background-color: #eee;
  /*soft grey*/
}

.table-view tr:nth-child(even) {
  background-color: #fff;
  /*plain old white*/
}

.table-view tr {
  transition: 0.5s;
}

/* .table-view tr:hover {
  background: #87cefa;
} */



.sechBtn {
  color: #fff;
  text-transform: uppercase;
  padding: 5px 5px;
  background-color: #436ad8;
  font-size: 14px;
  /* margin-top: 3px; */
  display: block;
  text-align: center;
  border-radius: 6px;
  height: 35px;
  line-height: 23px;
  /* width: 90px; */
}

.tp-form .form-control {
  padding: 6px 6px;
}

.tp-form .form-control {
  margin-bottom: 10px;
}

.tr-red {
  background-color: #f00 !important;
  color: #fff;
}

.tr-green {
  background-color: #1ba708 !important;
  color: #fff;
}

.tr-orange {
  background-color: #e59c00 !important;
  color: #fff;
}

.search-4 {
  text-align: center;
}

.page-link-prev {
  border: 0;
  width: 60px !important;
}

.page-link-prev:hover {
  background-color: transparent;
}

.page-link:hover {
  background: linear-gradient(to right, #436ad8 0, #b264b9 100%);
  color: #fff;
}

.navbar-vertical .navbar-nav .nav-link.active {
  background: linear-gradient(to right, #436ad8 0, #b264b9 100%);
  color: #fff;
}

.navbar-vertical .navbar-nav .nav-link.page-link-prev.active {
  background: transparent;
}

.page-link.page-link-prev:hover {
  background: transparent;
  color: #8392ab;
  border: 0;
}

.page-link.page-link-prev:focus {
  background: transparent;
  color: #8392ab;
  border: 0;
  box-shadow: none;
}

.page-link.page-link-prev:focus-within {
  background: transparent;
  color: #8392ab;
  border: 0;
  box-shadow: none;
}

.page-item.active .page-link {
  background: linear-gradient(to right, #1f4dd1 0, #cf32dd 100%);
  color: #fff;
}

.checkd {
  color: #1ba708;
}
.crossed {
  color: #f12b2b;
}

.color {
  color:#1ba708
}

.reject {
  color: #f12b2b;
}

@media (max-width: 1200px) {
  .sechBtn {
    width: 165px !important;
  }
}

@media screen and (max-width: 767px) {
  .pdrt {
    padding-right: 0 !important;
  }

  .tp-form .form-control {
    padding: 6px 5px;
  }

  .navbar.mx-4 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .search-4 .sechBtn {
    display: inline-block;
  }
}

@media screen and (max-width: 574px) {
  .search-4 .sechBtn {
    display: inline-block;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}

.exdd {
  width: 165px;
  background-color: #436ad8;
  color: #fff;
  border-radius: 6px;
  text-align: center;
}
.excel_sheet a{
  display: inline-block;
}
.ex_dow_name{
  flex: 1;
  text-align: center;
  font-size: 14px;
}
@media screen and (min-width: 1301px) {
  .exdd {
    width: 100%;
  }
}
@media screen and (max-width: 1201px) {
  .excel_sheet{
    display:flex;
    align-items:baseline;
   }
  .excel_sheet a {
   background-color: transparent;
  }
  .excel_sheet > .sechBtn {
    width: 0px !important;
  }
}
@media screen and (max-width: 1201px) {
  .widr {
    display:flex;
    align-items:center;
   }

  .widr > .sechBtn {
    width: 32px !important;
    text-align:center;
    display: flex;
  }
  }






