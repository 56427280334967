.dwp_btn {
    color: whitesmoke !important;
    position: sticky !important;
    bottom: 0px !important;
    background-color:#7f5dc6 !important;
    width: 100% !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
    background-color:#5a7ee1 !important;
}

.css-1km1ehz.Mui-selected {
    background-color: #5a7ee1 !important;
}

#demo-multiple-chip {
    font-size: 14px !important;
}