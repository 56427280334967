.message-cell {
    max-width: 200px;
    overflow: hidden;
    /* text-overflow: ellipsis;
    white-space: nowrap; */
    word-wrap: break-word;
    white-space: pre-wrap!important;
    /* position: relative; */
    font-size: 10px !important;
    font-weight: bold;
  }
  
  .message-cell:hover::after {
    content: attr(data-full-message);
    position: absolute;
    left: 0;
    top: 100%;
    background: #fff;
    border: 1px solid #ccc;
    padding: 5px;
    white-space: normal;
    z-index: 1000;
  }
  