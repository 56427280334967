.user-deposit {
    background: #eee;
    border-bottom: 1px solid #eee;
    color: black;
    line-height: 35px;
}

/* .amt{
    padding: 3px 30px !important;
} */

.spn-amt{
    /* margin-left: 5px !important; */
    border-bottom: 2px solid black;
    letter-spacing: 1px;
    font-size: 14px;
    padding: 0 5px;
}

.total-credit {
    background:green;
    padding: 0 5px;
    color: #fff;
}
.total-approved {
    background:green;
    padding: 0 5px;
    color: black;
}
.total-approved-blue {
    background:#0064fff7;
    padding: 0 5px;
    color: black;
}
.total-pending {
    background:yellow;
    padding: 0 5px;
    color: black;
}
.total-debit {
    background:red;
    padding: 0 5px;
    color: #fff;
}
.total-rejected {
    background:red;
    padding: 0 5px;
    color: black;
}
.total-reverse {
    background:orange;
    padding: 0 5px;
    color: black;
}
.total-onhold {
    background:#f6d08b;
    padding: 0 5px;
    color: black;
}

.content-bet {
    /* background: #eee !important; */
    padding: 0 !important;
}

.bethis-card {
    display: flex;
    border-bottom: 1px solid #ddd;
    height: 32px;
}

.bet-market-name {
    margin: 0;
}

.bet-content-container {
    margin: 5px 10px;
    display: flex;
    flex-direction: column;
}

.card-fr {
    background: #eee !important;
}

.text-bethis {
    margin: 0 10px;
}

.withdraw-approved {
    background-color: #84d184 !important;
    color: black;
}

.withdraw-pending {
    background-color: #fff !important;
    color: black;
}

.withdraw-rejected {
    background-color: #ff7e7e !important;
    color: black;
}
.withdraw-onhold {
    background-color: #f6d08b !important;
    color: black;
}
.withdraw-reverse {
    background-color: #f2f2ad !important;
    color: black;
}

.all-fundRequest-checks{
    max-width: 100%;
    margin: auto;
    display: flex;
    
   
}