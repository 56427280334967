@media (max-width: 1200px) {
    .lbl {
        font-size: 8px;
    }
}

.icon-button-user {
    background: #fff !important;
    border-radius: 0px !important;
    height: 26px !important;
    margin-bottom: 3px !important;
}